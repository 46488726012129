<template>
  <div id="newsStoryContent">
    <p id="Text1">
      In the next few weeks a major change will be added to LEGO Universe - Minifigure levels! As you complete Missions and Achievements, your minifigure will increase in level – earning new rewards and abilities. This leveling system will provide minifigures with a smoother game play experience and offers even more minifigure customization!
    </p>
    <div class="center">
      <!-- pic1E8434DC0B05025454D1739A6936AB00.jpg -->
      <img src="@/assets/news-network/level-up.jpg" class="rounded">
    </div>
    <p>
      Leveling in LEGO Universe is a breeze!  You won't need to spend hours upon hours grinding enemies for experience points.  All you need to do is earn U-Score points by completing Missions and Achievements! After the new system goes into place, even Daily Missions will count!
    </p>
    <p>
      Want some examples? To reach level 2, you must earn 35 U-Score. Level 5, will require 390 U-Score, and so on.  These numbers are subject to change based on internal testing. Familiarize yourself with your Passport to quickly find and identify Achievements you can complete to level up!
    </p>
    <p>
      With leveling also comes leveling rewards!  At certain milestone levels (like 5, 10, and 15), your minifigure will earn cool prizes, including up to 40 more backpack slots, a rocking owl companion who is happy to perch on your shoulder, a permanent speed boost to your minifigure, and exciting new equipment!
    </p>
    <p>
      As part of the new leveling system, another big change is coming.  All in-game items will have a level requirement.  This means that in order to use or buy this item, you must be the specified level first.  For example, the Visored Racing Helmet has a level requirement of 20, the Crux Planet Shield has a level requirement of 25, and the Spike Hammer has a level requirement of 30.  As you level up, your minifigure will be able to unlock more items, allowing you to customize your minifigure to be exactly how you want it to be!
    </p>
    <p>
      Concerned about how this change will affect your minifigures?  Worry not!  All existing minifigures will automatically have their level calculated by their existing U-Score.  The U-Scores of existing Achievements and Missions are having their U-Score balanced for this new system – your minifigures will receive credit for every Achievement and Mission they have completed in the past .  After the new system goes into place, we will recalculate your existing U-Score and transition you smoothly into the new system.
    </p>
    <p>
      Any items that have already been <b>linked</b> to your minifigure will <b>not</b> be restricted to the level requirements.  This means that if your U-Score places you at level 19, but you already have a linked Spike Hammer from your past level-free life, you can still use your existing linked Spike Hammer with no worries! Remember, you must have the item linked, not just in your backpack or vault – but <b>linked</b>!
    </p>
    <p>
      Stand by for more breaking news to come!  In the meantime, crack open that Passport and start hunting U-Score points like there’s no tomorrow!
    </p>
    <p>
      Discuss this article on the <router-link to="/messageboards/3971621">message boards</router-link>!
    </p>
  </div>
</template>
